import { render, staticRenderFns } from "./common-product-card.vue?vue&type=template&id=28920a91&scoped=true&"
import script from "./common-product-card.vue?vue&type=script&lang=ts&"
export * from "./common-product-card.vue?vue&type=script&lang=ts&"
import style0 from "./common-product-card.vue?vue&type=style&index=0&id=28920a91&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28920a91",
  null
  
)

export default component.exports