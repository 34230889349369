










































    import {
        Vue, Component, Prop,
    } from 'vue-property-decorator'
    import { State, Getter } from 'vuex-class'

@Component
    export default class BillingDetailList extends Vue {
    @State('langCode') langCode

    @State('countryId') countryId

    @State('countryCurrency') currency

    // 列表类型: overdue、history
    @Prop({ required: true }) columns!: Array<any>

    @Prop({ required: true }) queryCallback!: Function

    tableData: any[] = []

    billingDetailLoading: boolean = true

    pageInfo: any = {
        limit: 10,
        page: 1,
    }

    canScroll: boolean = true

    billStatusMap: any = {
        1: {
            label: 'label_open',
            color: '#3DC046',
        },
        2: {
            label: 'label_paid',
            color: '#EC6D20',
        },
        4: {
            label: 'label_overdue',
            color: '#E51C23',
        },
    }

    scrollLoadTableData() {
        if (this.canScroll && !this.billingDetailLoading) {
            this.pageInfo.page++
            this.getTableData()
        }
    }

    getTableData() {
        if (this.pageInfo.page === 1) {
            this.tableData = []
        }
        this.billingDetailLoading = true
        this.queryCallback(this.pageInfo).then(res => {
            this.canScroll = res.tableData.length === this.pageInfo.limit
            this.tableData = this.tableData.concat(res.tableData || [])
            this.billingDetailLoading = false
        })
    }
    }
