
import api from '~/api'
import $config from '~/config'

export default {
    freeGiftNotify(){
        return function({giftSkuPicList, duration = 3000, freeGiftTips}){
            let tips = `<span>${freeGiftTips}</span>`
            let imgHtml = ''
            giftSkuPicList.slice(0, 3).map(item=>{
                imgHtml += `<div style="display: inline-block; width: 55px; height: 55px"><img src='${$config.imgDomainName}${item}?x-oss-process=image/resize,w_1500,/format,webp' 
                style="width: 55px; border-radius: 4px; vertical-align: middle"/></div>`
            })

            this.$notify({
                duration,
                title: '',
                dangerouslyUseHTMLString: true,
                message: `${tips}${imgHtml}`,
                customClass: 'free-gift-notify-style'
            });
        }
    },
    batchSave(customizeValue, elementId) {
        let params = {
            datas: [{
                appId: 'EGG_MALL_WEB',
                appVersion: 'EGG_MALL_WEB',
                carrier: 'EGG_MALL_WEB',
                customizeValue,
                deviceId: 'EGG_MALL_WEB',
                dynamicResourceType: 1,
                elementId,
                eventTime: 1,
                eventType: 1,
                ip: 'EGG_MALL_WEB',
                isFirstTime: 1,
                latitude: 'EGG_MALL_WEB',
                libVersion: 'EGG_MALL_WEB',
                longitude: 'EGG_MALL_WEB',
                manufacturer: 'EGG_MALL_WEB',
                model: 'EGG_MALL_WEB',
                networkType: 'EGG_MALL_WEB',
                operatingSystem: 'WEB',
                operatingSystemVersion: 'EGG_MALL_WEB',
                referPage: 'EGG_MALL_WEB',
                screenHeight: 'EGG_MALL_WEB',
                screenName: 'EGG_MALL_WEB',
                screenOrientation: 1,
                screenWidth: 'EGG_MALL_WEB',
                sessionId: 'EGG_MALL_WEB',
                timeZone: 'EGG_MALL_WEB',
                userId: localStorage.getItem('userId') || 0,
                businessPlatform: 12,
            }],
        }
        api.event_tracking(JSON.stringify(params))
    },
    handleImpressionAnalyze(list, source){
        console.log('re', list);
        let advertisementBuryData = []
        list.map(item=>{
            if(item.advertisementId){
                advertisementBuryData.push({
                    "advertisementPlacement": item.advertisementPlacement,
                    "advertisementId": item.advertisementId,
                    "sourcePage": source,
                    "skuOrgId": item.skuOrgId,
                })
            }
        })
        let customizeValue = {
            advertisementBuryData
        }

        if(list.length){
            this.batchSave(customizeValue, 'Ads_SKU_Impression')
        }
    }
}