











    import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    name: 'Pagination',
})
    export default class extends Vue {
    @Prop(Number)
    total!: Number

    @Prop(Number)
    currentPage!: Number

    @Prop(Number)
    pageSize!: Number

    // 翻页后页面是否需要滚动到顶部
    @Prop({ required: false, default: false }) noScrollToTop!: boolean

    handleCurrentChange(val) {
        if (!this.noScrollToTop) {
            document.body.scrollIntoView()
        }
        this.$emit('changePage', val)
    }
    }
