
















































































import {
    Vue,
    Component,
    Prop,
} from 'vue-property-decorator'
import $config from '~/config'

@Component({
    name: 'ProductCover',
})
export default class extends Vue {
    get imgDomainName() {
      return $config.imgDomainName
    }

    @Prop({
        required: true,
        default() {
            return {}
        },
    }) productInfo!: any

    @Prop({default: true}) coverVisible!: boolean

    @Prop({default: 26}) textHeight!: number

    @Prop({default: true}) showADLabel!: boolean

    getFontSize(fontSize) {
        const style: any = {}
        if (fontSize >= 12) {
            style['font-size'] = `${fontSize}px;`
        } else {
            style['font-size'] = '12px'
            style.zoom = fontSize / 12
        }
        return style
    }

    clickCover() {
        this.$emit('click', this.productInfo)
    }
}
