























































































































    import {
        Component, Prop, Vue, Model, Watch,
    } from 'vue-property-decorator'
    import {cloneDeep} from 'lodash'
    import ImgLazyLoad from '~/components/img-lazy-load.vue'

@Component({
    components: {
        ImgLazyLoad,
    },
})
    export default class Select extends Vue {
    @Prop() data;

    @Prop() placeholder;

    @Prop({ required: true }) type!: string;

    @Prop({required: false}) isShowIcon!: boolean

    @Prop({required: false}) defaultOptionId!: string | number

    @Prop() label;

    @Prop({required: false, default: false}) isBrand!: boolean;

    @Model('change') model;

    @Prop({required: false}) setDefault!: boolean

    selectShow = false;

    disabled = false;

    multiDatas: any[] = [];

    initMultiDatas: any[] = [];

    showNum: number = 0

    isFilter = false;

    displayLabel : string = '';

    get showLabel() {
        // if (this.type === 'single') {
        //     return this.model.label
        // }
        return ''
    }

    get selectedItems() {
        return this.multiDatas.filter(item => item.selected) || []
    }

    @Watch('data')
    dataChange(val) {  
        if (this.type === 'multi') {
            let result: any[] = []
            val.map(item => {
                result.push({ ...item, selected: false })
            })
            this.multiDatas = cloneDeep(result)          
            this.initMultiDatas = cloneDeep(result)
        }
    }

    @Watch('setDefault', {immediate: true})
    watchSetDefault(val) {
        if (val) {
            this.applySelect()
        }
    }

    mounted() {
        window.addEventListener('click', this.handleClick)
        if (this.type === 'single') {
            if (this.defaultOptionId) {
                this.selectItem(this.data.find(item => +item.value === +this.defaultOptionId), true)
            } else {
                this.selectItem(this.data[0], true)
            }
        }
        this.multiDatas = cloneDeep(this.data)
        this.initMultiDatas = cloneDeep(this.data.map(val => {
            if (val.selected === true) val.selected = false
            return val
        }))
    }

    beforeDestory() {
        window.removeEventListener('click', this.handleClick)
    }

    showSelect() {
        if (this.disabled) {
            return
        }
        this.selectShow = !this.selectShow
    }

    selectItem({ value, label }, isInitial?) {
        this.$emit('change', { value, label, isInitial })
        this.selectShow = false
    }

    multiSelectItem({ value, selected }) {
        this.multiDatas.map(item => {
            if (item.value === value) {
                item.selected = !selected
            }
        })
    }

    applySelect() {
        this.$emit('apply', this.selectedItems)
        this.selectShow = false
        this.showNum = this.selectedItems.length
    }

    handleClick(e) {
        if (this.$el.contains(e.target)) {
            return false
        }
        this.selectShow = false
    }

    clearSelectedItems() {
        this.multiDatas = cloneDeep(this.initMultiDatas)
        this.showNum = 0
        this.$emit('apply', this.selectedItems)
        this.selectShow = false
    }

    changeLabel(value) {
        if (value.value == 0) {
            this.isFilter = false
        } else {
            this.displayLabel = value.label
            this.isFilter = true
        }
    }

    changeStyle(value) {
        let placeholderEl:any = this.$refs.placeholder
        if (value.length) {
            placeholderEl.style.color = '#ec6d20'
        } else {
            placeholderEl.style.color = '#A1A1A1'
        }
    }
    }
