


































    import {
        Vue, Component, Prop, PropSync, Watch,
    } from 'vue-property-decorator'

@Component({
    name: 'SimplePagination',
})
    export default class extends Vue {
    @Prop(Number)
    total!: any

    @Prop(Number)
    limit!: any

    @PropSync('page', {required: true}) curPage

    inputPage: any = 1

    get totalPage() {
        return Math.ceil(this.total / this.limit)
    }

    handleChangePage(type) {
        let page = type === 'prev' ? (this.curPage - 1) : (this.curPage + 1)
        this.$emit('handleChangePage', page)
    }

    gotoPage(value) {
        if (/^[0-9]*$/.test(value)) {
            let page = parseInt(value, 10)
            if (page <= 0 || page > this.totalPage) {
                this.inputPage = this.curPage
                return
            }
            this.$emit('handleChangePage', page)
        } else {
            this.inputPage = this.curPage
        }
    }

    inputChange(val) {
        this.inputPage = val.replace(/[^\d]+/g, '')
    }

    @Watch('curPage')
    onCurPage(val) {
        this.inputPage = val
    }
    }
