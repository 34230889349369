






































import {
    Vue, Component,Prop
} from 'vue-property-decorator'
import {State} from 'vuex-class'
import api from '~/api'

@Component(<any> {
    components:{
    },
})
export default class KnowledgeRightSide extends Vue{
    @State('langCode') langCode

    @Prop({required: false}) hotArticleList!: object

    @Prop({required: false}) value!: string | number

    @Prop({required: false,default: true}) showSearchPart!: boolean

    tagsList: any = []

    mounted(){
        api.get_hot_tags().then((res)=>{
            this.tagsList = res.data
            this.tagsList.map((tag)=>{
                this.$set(tag,'isActive',false)
                if(this.$route.query.tagId && +this.$route.query.tagId === tag.tagId){
                    tag.isActive = true
                }
            })

        })
    }

    searchArticleFunc(){
        this.$emit('searchArticleFunc')
    }

    inputChange(val) {
        this.$emit('input', val)
    }

    jumpToArticleDetail(article){
        this.$router.push(this.localePath({
            name:'ArticleDetails',
            params:{
                id:article.articleId
            }
        }))
    }

    handleTag(tag){        
        if(!tag.isActive){
            this.tagsList.map(tag=>{
                tag.isActive = false
            })
            tag.isActive = true
            this.$emit('handleTag',tag,'active')
        }else{
            this.$emit('handleTag',tag,'not-active')
            tag.isActive = false
        }
    }

    clearSearch(){
        this.tagsList.map(tag=>{
            tag.isActive = false
        })
    }

}
