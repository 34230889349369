import { render, staticRenderFns } from "./knowledge-right-side.vue?vue&type=template&id=492a6b72&scoped=true&"
import script from "./knowledge-right-side.vue?vue&type=script&lang=ts&"
export * from "./knowledge-right-side.vue?vue&type=script&lang=ts&"
import style0 from "./knowledge-right-side.vue?vue&type=style&index=0&id=492a6b72&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492a6b72",
  null
  
)

export default component.exports