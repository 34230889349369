



































import {
    Vue, Component,
} from 'vue-property-decorator'
import {State} from 'vuex-class'

@Component(<any> {
    async asyncData(context) {
        let header: any = {
            countryId: context.store.state.countryId,
        }
    }
})
export default class CommonNoResult extends Vue {
    @State('countryId') countryId


    openEmailLInk(){
        window.open('mailto:info.aakorea@kr.bosch.com')
    }
}
