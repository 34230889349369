

































































import {
    Vue, Component, Prop, PropSync, Watch
} from 'vue-property-decorator'
import api from '~/api'
import utils from '~/utils'

@Component(<any>{
    components: {
    },
})
export default class ChangePaymentMethodDlg extends Vue {
    @PropSync('visible', {
        required: true,
        default: false,
    }) dlgVisible!: boolean

    @Prop({
        required: true,
        default() {
            return []
        },
    }) orderIdList!: any[]

    paymentMethodList: any[] = []

    paymentMethodObj: any = {}

    confirmLoading: boolean = false

    @Watch('orderIdList', { immediate: true })
    handleOrderChange(val) {
        if (!(<any>process).server && val && val.length) {
            this.initData()
        }
    }

    initData() {
        api.get_order_detail({id: this.orderIdList[0]}).then(res => {
            let data = (res && res.data) || {}
            this.getOrderPaymentMethodList(data.paymentMethod, data.paymentChannel)
        })
    }

    getOrderPaymentMethodList(paymentMethod, paymentChannel) {
        api.getOrderPaymentMethodList({
            orderIdList: this.orderIdList,
        }).then(res => {
            this.paymentMethodList = res.data || []
            this.paymentMethodObj = this.paymentMethodList.find(item => item.paymentChannel === paymentChannel && item.paymentMethod === paymentMethod) || {}
        })
    }

    changePaymentMethod() {
        if (!this.paymentMethodObj || !this.paymentMethodObj.paymentMethod) {
            return
        }
        this.confirmLoading = true
        api.updateOrderPaymentMethod({
            orderIdList: this.orderIdList,
            paymentMethod: this.paymentMethodObj.paymentMethod,
            paymentChannel: this.paymentMethodObj.paymentChannel
        }).then(res => {
            if (res.data.resultTips) {
                if (res.data.reminderMethod === 'TOAST') {
                    this.$message.warning(res.data.resultTips)
                    this.handleSuccess();
                } else {
                    this.$confirm('', res.data.resultTips, {
                        confirmButtonText: this.$t('label_ok'),
                        showCancelButton: false,
                        showClose: false,
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                    }).then(() => {
                        this.handleSuccess();
                    })
                }
            }
            this.dlgVisible = false
        }).finally(() => {
            this.confirmLoading = false
        })
    }

    handleSuccess() {
        if (this.orderIdList.length > 1) {
            this.$router.push(this.localePath({
                name: 'orderListPage',
            }))
        } else {
            this.$router.push(this.localePath({
                name: 'orderDetailPage',
                params: {
                    id: this.orderIdList[0],
                },
            }))
        }
    }
}
