















































 import {
     Vue, Component, Watch,
 } from 'vue-property-decorator'
 import {State} from 'vuex-class'

@Component
 export default class PolicyHeader extends Vue {
   @State('langCode') langCode

   // @State('protocolList') protocolList

   @State('selectedPolicyId') selectedPolicyId

   selectedPolicyIdinHtml:any

   selectedProtocolTypeId: number | string = ''

   selectedProtocolTermsType: string = ''

   protocolList: any[] = []

   created() {
     // 获得国家
     console.log('this.$store.state.countryCode', this.$store.state.countryCode)

     if (this.$store.state.countryCode === 'VN') {
         this.protocolList = this.$store.state.protocolList.vi
     } else if(this.$store.state.countryCode === 'KR'){
         this.protocolList = this.$store.state.protocolList.ko
     }else{
         this.protocolList = this.$store.state.protocolList.default
     }
     this.selectedProtocolTypeId = +this.$store.state.selectedProtocolTermsType
     if ([13, 12, 5, 4].includes(this.selectedProtocolTypeId)) {
         this.selectedProtocolTypeId = 13
     } else {
         this.selectedProtocolTypeId = +this.$store.state.selectedProtocolTermsType
     }
     this.selectedPolicyIdinHtml = this.$store.state.selectedPolicyId + ''
   }

   mounted(){
       if(this.$route.query.pointsPolicy){
           this.change(8)
       }
   }

   selectProtocolType(item) {
     this.$store.commit('SET_SELECTED_PROTOCOL_ID', item.termsConditionsId)
     this.$store.commit('SET_SELECTED_POLICY_ID', item.termsConditionsId + '')
     window.localStorage.setItem('selectedPolicyId', item.termsConditionsId)
     if (item.termsConditionsId === 13) {
         this.$router.push(this.localePath({
             name: 'termsAndConditions',
         }))
     } else if(item.termsConditionsId === 27){
         this.$router.push(this.localePath({
             name: 'ConsentToCollectionAndUseOfPersonalInformation',
         }))
     }else if(item.termsConditionsId === 28){
         this.$router.push(this.localePath({
             name: 'ConsentToOverseasAndUseOfPersonalInformation',
         }))
     }else{
         this.$router.push(this.localePath({
             name: 'privacyPolicy',
         }))
     }
   }

 change(com) {
     let key
     if(com.$vnode){
        key = com.$vnode.key 
     }else{
         key = com
     }
     console.log('teeee',com);
     
     switch (key) {
       case 12:
         this.toPolicyHtml('shippingPolicy', '12')
         break
       case 5:
         this.toPolicyHtml('warrantyPolicy', '5')
         break
       case 4:
         this.toPolicyHtml('returnPolicy', '4')
         break
       case 7:
         this.toPolicyHtml('privacyPolicy', '7')
         break
       case 13:
         this.toPolicyHtml('termsAndConditions', '13')
         break
     case 8:
         this.toPolicyHtml('pointsPolicy', '8')
         break
     default:
         break
     }
   }

   toPolicyHtml(value, id) {
         console.log('test',value,id);
         
       let termsConditionsId
       value === 'privacyPolicy' ? termsConditionsId = 7 : termsConditionsId = 13
       this.$store.commit('SET_SELECTED_PROTOCOL_ID', termsConditionsId)
       this.$store.commit('SET_SELECTED_POLICY_ID', id)
       window.localStorage.setItem('selectedPolicyId', id)
       this.$router.push(this.localePath({
           name: value,
       }))
   }
 }
